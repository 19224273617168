import { Injectable } from '@angular/core';

export interface Listing {
    ListingID: number,
    StreetAddress: string,
    City: string,
    State: string,
    ZIP: string,
    Longitude: number,
    Latitude: number,
    ListingStatus: string,
    ListingPrice: number,
    SellingPrice: number | null,
    ListingDate: Date,
    SellingDate: Date | null,
    ListingBeds: number,
    ListingBaths: number,
    ListingSqft: number,
    PrimaryImage: string,
    OutLink: string | null,
    ShowInAmnonListings: boolean,
    MLSLegalTextThumbnail: string,
    MLSLegalTextListings: string,
    ModifiedDate: Date,
    ListAgentFullName: string,
    BuyerAgentFullName: string | null
}

@Injectable()
export class ListingsService {
    public getListings(): Promise<Listing[]> {
        return fetch("/api/listings").then(res => res.json());
    }

    public getThumbnailURL(l: Listing): string | null {
        if (l.PrimaryImage == null) {
            return null;
        } else {
            return `https://dashreal.com/api/listings/ListingImage?listingID=${l.ListingID}&mediaFile=${l.PrimaryImage}`;
        }
    }
}
