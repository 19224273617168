import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BrokerContactFormComponent } from './broker-contact-form.component';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [BrokerContactFormComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule
    ],
    exports: [BrokerContactFormComponent]
})
export class BrokerContactFormModule { }
