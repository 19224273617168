import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare const initNavbar: any;  // from src/assets/js/navbar.js

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html'
})
export class NavbarComponent implements AfterViewInit {
    @ViewChild('navbarCollapse', { static: true }) navbarCollapseRef!: ElementRef;
    @ViewChild('navbarToggler', { static: true }) navbarTogglerRef!: ElementRef;

    @Input() showExtraNavbar: boolean = false;
    @Input() notToggleable: boolean = false;

    prevUrl: string;

    get atTop(): boolean {
        return window.pageYOffset === 0;
    }

    constructor(private router: Router) {
        this.prevUrl = this.getUrlWithoutFragment(router.url);

        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                const newUrl: string = this.getUrlWithoutFragment((e as NavigationEnd).url);

                if (newUrl === this.prevUrl) {  // fragment on same page -> hide navbar hamburger menu
                    const navbarCollapse: HTMLElement = this.navbarCollapseRef.nativeElement;
                    if (navbarCollapse.classList.contains('show')) {
                        const hamburger: HTMLElement = this.navbarTogglerRef.nativeElement;
                        hamburger.click();
                    }
                }
                document.getElementsByTagName('html')[0].style.removeProperty('overflow');
                document.getElementsByTagName('body')[0].style.removeProperty('padding-right');

                this.prevUrl = newUrl;
            }
        })
    }

    ngAfterViewInit() {
        initNavbar();
    }

    private getUrlWithoutFragment(url: string): string {
        return url.split('#')[0];
    }
}
