import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { jarallax, jarallaxElement, jarallaxVideo } from 'jarallax';

declare const initAOS: any;  // from src/assets/js/aos.js
declare const initChoices: any;  // from src/assets/js/choices.js
declare const initCountup: any;  // from src/assets/js/countup.js
declare const initDropdown: any;  // from src/assets/js/dropdown.js
declare const initFancybox: any;  // from src/assets/js/fancybox.js
declare const initLazysizes: any;  // from src/assets/js/lazysizes.js
declare const initModal: any;  // from src/assets/js/modal.js
declare const initPolyfills: any;  // from src/assets/js/polyfills.js
declare const initPopovers: any;  // from src/assets/js/popovers.js
declare const initTooltips: any;  // from src/assets/js/tooltips.js
declare const initTyped: any;  // from src/assets/js/typed.js

jarallaxElement();
jarallaxVideo();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title: string = 'BruckTeamWeb';
    prevUrl: string = '';
    loading: boolean = false;

    constructor(private router: Router) {
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationStart) {
                this.loading = true;
            } else if (e instanceof NavigationEnd) {
                this.loading = false;

                const newUrl: string = (e as NavigationEnd).url.split('#')[0];  // new URL without fragment
                if (newUrl !== this.prevUrl) {
                    this.initThemeJS();
                }
                this.prevUrl = newUrl;
            }
        })
    }

    initThemeJS() {
        initAOS();
        initChoices();
        initCountup();
        initDropdown();
        initFancybox();
        initLazysizes();
        initModal();
        initPolyfills();
        initPopovers();
        initTooltips();
        initTyped();

        let jarallaxElems: NodeListOf<Element> = document.querySelectorAll("[data-jarallax]");
        jarallaxElems.forEach(elem => {
            jarallax(elem, {
                speed: Number(elem.getAttribute('data-speed'))
            })
        });

        jarallax(document.querySelectorAll("[data-jarallax-video]"), {});
        jarallax(document.querySelectorAll("[data-jarallax-element]"), {});
    }
}
