import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FormComponent, SubmitService } from 'blog-lib';

@Component({
    selector: 'broker-contact-form',
    templateUrl: './broker-contact-form.component.html'
})
export class BrokerContactFormComponent extends FormComponent {
    @Input('broker-name') brokerName: string = '';

    firstName: string = '';
    lastName: string = '';
    email: string = '';
    phone: string = '';
    message: string = '';
    marketingConsent: boolean = false;

    constructor(private submitService: SubmitService) {
        super();
    }

    /**
     * Helper method for determining general vs. specific form text
     */
    isGeneric(): boolean {
        return this.brokerName === '';
    }

    submit(): Observable<string> {
        return this.submitService.submit('broker-contact', {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            brokerName: this.brokerName ? this.brokerName : 'General',
            message: this.message,
            marketingConsent: this.marketingConsent
        });
    }
}
