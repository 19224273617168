<div class="position-relative">
    <div class="shape shape-bottom shape-fluid-x svg-shim text-black-2">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
        </svg>
    </div>
</div>

<footer class="py-8 py-md-11 bg-black-2 text-white">
    <div class="container">
        <div class="row justify-content-center text-white">
            <div class="col-9 col-md-4 col-lg-2 mb-6">
                <div class="d-flex flex-md-column justify-content-center align-items-center">
                    <a class="w-25 w-md-50 w-lg-75 mr-6 mr-md-0 mb-md-6 text-white"
                       style="min-width: 75px; height: min-content;"
                       inlineSVG="assets/img/brands/bruck-logo.svg"
                       routerLink="/"></a>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2 offset-md-1">
                <h4 class="font-weight-bold text-uppercase">Services</h4>
                <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                    <li class="mb-3">
                        <a class="text-white" routerLink="/buyers">Buyers</a>
                    </li>
                    <li class="mb-3">
                        <a class="text-white" routerLink="/sellers">Sellers</a>
                    </li>
                    <li class="mb-3">
                        <a class="text-white" routerLink="/developers">Developers</a>
                    </li>
                </ul>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <h4 class="font-weight-bold text-uppercase">About Us</h4>
                <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                    <!--<li class="mb-3">
                        <a class="text-white" routerLink="/about-us">About Us</a>
                    </li>-->
                    <li class="mb-3">
                        <a class="text-white" routerLink="/team">Meet the Team</a>
                    </li>
                    <li class="mb-3">
                        <a class="text-white" routerLink="/contact">Contact Us</a>
                    </li>
                </ul>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <h4 class="font-weight-bold text-uppercase">Legal</h4>
                <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                    <li class="mb-3">
                        <a class="text-white" routerLink="/terms-of-use">Terms of Use</a>
                    </li>
                    <li class="mb-3">
                        <a class="text-white" routerLink="/privacy-policy">Privacy Policy</a>
                    </li>
                </ul>
            </div>
            <div class="col-6 col-md-0"></div>
        </div>
    </div>
</footer>
