<form #form="ngForm" novalidate [ngClass]="submitted ? 'was-validated' : 'needs-validation'">
    <div class="row">
        <div class="col-12 col-md-6">
            <!-- First name -->
            <div class="form-label-group">
                <input type="text"
                       class="form-control form-control-flush"
                       id="firstName"
                       placeholder="First name"
                       required
                       [(ngModel)]="firstName" name="firstName">
                <label for="firstName">First name</label>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <!-- Last name -->
            <div class="form-label-group">
                <input type="text"
                       class="form-control form-control-flush"
                       id="lastName"
                       placeholder="Last name"
                       required
                       [(ngModel)]="lastName" name="lastName">
                <label for="lastName">Last name</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <!-- Email -->
            <div class="form-label-group">
                <input type="email"
                       class="form-control form-control-flush"
                       id="email"
                       placeholder="Email"
                       required
                       [(ngModel)]="email" name="email">
                <label for="email">Email</label>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <!-- Phone -->
            <div class="form-label-group">
                <input type="tel" pattern="^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$"
                       class="form-control form-control-flush"
                       id="phone"
                       placeholder="Phone number"
                       required
                       [(ngModel)]="phone" name="phone">
                <label for="phone">Phone number</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- "Where are you moving to?" -->
            <div class="form-label-group">
                <input type="text"
                       class="form-control form-control-flush"
                       id="movingToMessage"
                       placeholder="Where are you looking to move to?"
                       required
                       [(ngModel)]="movingToMessage" name="movingToMessage">
                <label for="movingToMessage">Where are you looking to move to?</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- Needs message -->
            <div class="form-label-group">
                <textarea class="form-control form-control-flush overflow-hidden"
                          id="needsMessage"
                          rows="2"
                          placeholder="Any needs that you would like us to know about?"
                          oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                          required
                          [(ngModel)]="needsMessage" name="needsMessage"></textarea>
                <label for="needsMessage">Any needs that you would like us to know about?</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-check mb-7 mb-md-9">
                <input class="form-check-input" type="checkbox" value="yes" id="marketingConsent" [(ngModel)]="marketingConsent" name="marketingConsent">
                <label class="form-check-label" for="marketingConsent">
                    I consent to receive SMS notifications, alerts & occasional marketing communication from The Bruck
                    Team. Message frequency varies. Message & data rates may apply. Text HELP to (425) 406-4199 for
                    assistance. You can reply STOP to unsubscribe at any time.
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- Submit -->
            <button #submit (click)="submitCallback()" type="submit" class="btn btn-block mt-3 mb-4 btn-primary lift">
                Buy Your Dream Home
            </button>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-auto">
            <p><a routerLink="/privacy-policy">Privacy Policy</a> | <a routerLink="/terms-of-use">Terms of Use</a></p>
        </div>
    </div> <!-- / .row -->
</form>
