import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogModule } from 'blog-lib';
import { FooterComponent } from '../footer/footer.component';
import { BuyersFormComponent } from '../forms/buyers-form/buyers-form.component';
import { NavbarComponent } from '../navbar/navbar.component';

// TODO: change route metadata
const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('../views/index/index.module').then(m => m.IndexModule),
        data: {
            title: "The Bruck Team",
            description: "The Bruck Team",
            keywords: ""
        }
    },
    {
        path: 'listing',
        loadChildren: () => import('../views/listing/listing.module').then(m => m.ListingModule),
        data: {
            title: "Listing | The Bruck Team",
            description: "Listing page test",
            keywords: ""
        }
    },
    {
        path: 'buyers',
        loadChildren: () => import('../views/buyers/buyers.module').then(m => m.BuyersModule),
        data: {
            title: "Buyers | The Bruck Team",
            description: "The Bruck Team",
            keywords: ""
        }
    },
    {
        path: 'sellers',
        loadChildren: () => import('../views/sellers/sellers.module').then(m => m.SellersModule),
        data: {
            title: "Sellers | The Bruck Team",
            description: "The Bruck Team",
            keywords: ""
        }
    },
    {
        path: 'developers',
        loadChildren: () => import('../views/developers/developers.module').then(m => m.DevelopersModule),
        data: {
            title: "Developers | The Bruck Team",
            description: "The Bruck Team",
            keywords: ""
        }
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('../views/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
        data: {
            title: "Privacy Policy | The Bruck Team",
            description: "Privacy Policy",
            keywords: ""
        }
    },
    {
        path: 'team',
        loadChildren: () => import('../views/team/team.module').then(m => m.TeamModule),
        data: {
            title: "Meet the Team | The Bruck Team",
            description: "The Bruck Team",
            keywords: ""
        }
    },
    {
        path: 'team/:name',
        loadChildren: () => import('../views/team/broker/broker.module').then(m => m.BrokerModule),
        data: {
            title: "Meet the Team | The Bruck Team",
            description: "The Bruck Team",
            keywords: ""
        }
    },
    {
        path: 'terms-of-use',
        loadChildren: () => import('../views/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule),
        data: {
            title: "Terms of Use | The Bruck Team",
            description: "Terms of Use",
            keywords: ""
        }
    },
    {
        path: 'about-us',
        loadChildren: () => import('../views/about-us/about-us.module').then(m => m.AboutUsModule),
        data: {
            title: "About Us | The Bruck Team",
            description: "The Bruck Team",
            keywords: ""
        }
    },
    {
        path: 'blog',
        loadChildren: () => import('blog-lib').then(m => m.BlogRoutingModule),
        data: {
            title: "Puget Sound Real Estate Buyers Blog and Guides",
            description: "Check out local real estate news, seattle-based resources and guides, and much more!",
            keywords: "Real estate news, real estate resources, real estate development resources, seattle real estate blog, seattle developer news"
        }
    },
    {
        path: 'affordability-calculator',
        loadChildren: () => import('blog-lib').then(m => m.AffordabilityCalculatorModule),
        data: {
            title: "Affordability Calculator | The Bruck Team",
            description: "",
            keywords: "",
            navbarConfig: {
                notToggleable: true
            },
            ctaForm: BuyersFormComponent
        }
    },
    {
        path: 'featured-properties',
        loadChildren: () => import('../views/featured-properties/featured-properties.module').then(m => m.FeaturedPropertiesModule),
        data: {
            title: "Featured Properties | The Bruck Team",
            description: "The Bruck Team",
            keywords: "Listings, properties",
            navbarConfig: {
                notToggleable: true
            }
        }
    },
    {
        path: 'contact',
        loadChildren: () => import('../views/contact/contact.module').then(m => m.ContactModule),
        data: {
            title: "Contact Us | The Bruck Team",
            description: "The Bruck Team",
            keywords: "contact",
            navbarConfig: {
                notToggleable: true
            }
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload'
        }),
        BlogModule.forRoot({
            navbarComponent: NavbarComponent,
            footerComponent: FooterComponent
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
