<div class="fixed-top" [class.shadow-light]="!atTop || notToggleable">
    <nav class="navbar navbar-expand-lg p-4"
         [ngClass]="{
            'navbar-dark': atTop && !notToggleable,
            'navbar-light bg-white': !atTop || notToggleable
         }">
        <!-- Brand -->
        <a class="navbar-brand py-0" style="width: 4em;" routerLink="/" inlineSVG="assets/img/brands/bruck-logo.svg"></a>
        <a class="navbar-brand-bw ml-6" style="width: 1px; min-width: 100px;" inlineSVG="assets/img/brands/compass-logo.svg"></a>

        <!-- Toggler -->
        <button #navbarToggler
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Collapse -->
        <div #navbarCollapse class="collapse navbar-collapse" id="navbarCollapse">
            <!-- Toggler -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fe fe-x"></i>
            </button>
            <!-- Navigation -->
            <ul class="navbar-nav ml-auto">
                <!--<li class="nav-item">
                    <a class="nav-link" href="https://www.compass.com/agents/amnon-bruck/" target="_blank">
                        Featured Properties
                    </a>
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" href="https://www.compass.com/homes-for-sale/seattle-wa/" target="_blank">
                        Search for Properties
                    </a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="z" data-toggle="dropdown" href="#" aria-haspopup="true" aria-expanded="false">
                        Buyers
                    </a>
                    <div class="dropdown-menu" aria-labelledby="z">
                        <div class="row">
                            <div class="col-12">
                                <!-- Heading -->
                                <a class="dropdown-header" routerLinkActive="active" routerLink="/buyers">
                                    Buyers
                                </a>
                                <a class="dropdown-item" routerLink="/buyers">
                                    Overview
                                </a>
                                <a class="dropdown-item" routerLink="/affordability-calculator">
                                    Affordability Calculator
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/sellers">
                        Sellers
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/developers">
                        Developers
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/blog">
                        Blog
                    </a>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="about-us" data-toggle="dropdown" href="#" aria-haspopup="true" aria-expanded="false">
                        About Us
                    </a>
                    <div class="dropdown-menu" aria-labelledby="about-us">
                        <div class="row">
                            <div class="col-12">
                                <!--<a class="dropdown-header" routerLink="/about-us">
                                    About Us
                                </a>-->
                                <a class="dropdown-header" routerLink="/team">
                                    About Us
                                </a>
                                <a class="dropdown-item" routerLink="/featured-properties">
                                    Featured Properties
                                </a>
                                <!--<a class="dropdown-item" href="#">
                                    Work With Us
                                </a>-->
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>

    <!-- "extra" navbar -->
    <nav *ngIf="showExtraNavbar"
         class="navbar navbar-expand-lg pt-0 pb-4"
         [ngClass]="{
            'navbar-dark': atTop && !notToggleable,
            'navbar-light bg-white': !atTop || notToggleable
         }">
        <div class="container">
            <ng-content></ng-content>
        </div>
    </nav>
</div>
