import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { AmnonContactModalComponent } from './amnon-contact-modal.component';
import { BrokerContactFormModule } from '../../../forms/broker-contact-form/broker-contact-form.module';


@NgModule({
    declarations: [AmnonContactModalComponent],
    imports: [
        CommonModule,
        FormsModule,

        BrokerContactFormModule
    ],
    exports: [
        AmnonContactModalComponent
    ]
})
export class AmnonContactModalModule { }
