<form #form="ngForm" novalidate [class]="submitted ? 'was-validated' : 'needs-validation'">
    <div class="row">
        <div class="col-6">
            <!-- First name -->
            <div class="form-group">
                <label for="firstName">First name</label>
                <input type="text"
                       class="form-control"
                       id="firstName"
                       placeholder="John"
                       required
                       [(ngModel)]="firstName" name="firstName">
            </div>
        </div>
        <div class="col-6">
            <!-- Last name -->
            <div class="form-group">
                <label for="lastName">Last name</label>
                <input type="text"
                       class="form-control"
                       id="lastName"
                       placeholder="Smith"
                       required
                       [(ngModel)]="lastName" name="lastName">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <!-- Email -->
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email"
                       class="form-control"
                       id="email"
                       placeholder="hello@example.com"
                       required
                       [(ngModel)]="email" name="email">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <!-- Phone Number -->
            <div class="form-group">
                <label for="phone">Phone</label>
                <input type="tel" pattern="^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$"
                       class="form-control"
                       id="phone"
                       placeholder="123-456-7890"
                       required
                       [(ngModel)]="phone" name="phone">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- Message -->
            <div class="form-group">
                <label for="message">What do you need help with?</label>
                <textarea class="form-control overflow-hidden"
                          id="message"
                          rows="1"
                          placeholder="..."
                          oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                          required
                          [(ngModel)]="message" name="message"></textarea>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-check mb-7 mb-md-9">
                <input class="form-check-input" type="checkbox" value="yes" id="marketingConsent" [(ngModel)]="marketingConsent" name="marketingConsent">
                <label class="form-check-label" for="marketingConsent">
                    I consent to receive SMS notifications, alerts & occasional marketing communication from The Bruck
                    Team. Message frequency varies. Message & data rates may apply. Text HELP to (425) 406-4199 for
                    assistance. You can reply STOP to unsubscribe at any time.
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- Submit -->
            <button #submit (click)="submitCallback()" class="btn btn-block btn-primary mt-3 mb-4 lift">
                Contact {{ isGeneric() ? "us" : brokerName }}
            </button>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-auto">
            <p><a routerLink="/privacy-policy">Privacy Policy</a> | <a routerLink="/terms-of-use">Terms of Use</a></p>
        </div>
    </div> <!-- / .row -->
</form>
