import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


export interface Review {
    id: number,
    agent: string,
    source: string,
    clientName: string,
    typeTags: string,
    pagesPresent: string,
    stars: number,
    qualityRating: number,
    text: string
}


export interface GetReviewsParameters {
    tag?: string,
    pageName?: string,
    page?: number,
    pageSize?: number
}


@Injectable()
export class ReviewsService {
    constructor(private http: HttpClient) { }

    public getReviews({ tag = undefined, pageName = undefined, page = 0, pageSize = 6 }: GetReviewsParameters): Observable<Review[]> {
        let url: string = '/api/reviews/';
        if (tag) {
            url += 'tag/' + encodeURIComponent(tag);
        } else if (pageName) {
            url += 'page/' + encodeURIComponent(pageName);
        } else {
            url += 'all';
        }
        url += `?page=${page}&pageSize=${pageSize}`;

        return (this.http.get(url, {
            responseType: 'json'
        }) as Observable<Review[]>).pipe(catchError(this.errorHandler));
    }

    public getReview(id: number): Observable<Review> {
        return (this.http.get('/api/reviews/data/' + id, {
            responseType: 'json'
        }) as Observable<Review>).pipe(catchError(this.errorHandler));
    }


    errorHandler(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {  // client error
            console.error('Error when retrieving project(s): ' + error.error.message);
        } else {  // server error
            console.error('Backend error, code ' + error.status);
        }
        return throwError('Something went wrong, please try again later');
    }
}
