import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ReviewsService } from './services/reviews.service';
import { ListingsService } from './services/listings.service';
import { AmnonContactModalModule } from '../modals/broker-contact-modal/amnon/amnon-contact-modal.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,

        AmnonContactModalModule
    ],
    providers: [
        ReviewsService,
        ListingsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
