<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" [style.display]="finished ? 'none' : 'flex'" [attr.aria-hidden]="finished">
            <div class="modal-body">
                <!-- Close -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <!-- Image -->
                <div class="text-center">
                    <img src="assets/img/illustrations/seattle-broker-team-the-bruck-team-illustration.png" alt="..." class="img-fluid mb-4" style="width: 200px;">
                </div>
                <!-- Heading -->
                <h2 class="font-weight-bold text-center mb-1" id="modalTitle">
                    Contact Amnon
                </h2>
                <!-- Text -->
                <p class="font-size-lg text-center text-muted mb-6 mb-md-8">
                    I can help you acquire or sell your property.
                </p>

                <!-- Form -->
                <form #form="ngForm" novalidate [class]="submitted ? 'was-validated' : 'needs-validation'">
                    <div class="row">
                        <div class="col-6">
                            <!-- First name -->
                            <div class="form-group">
                                <label for="firstName">First name</label>
                                <input type="text"
                                       class="form-control"
                                       id="firstName"
                                       placeholder="John"
                                       required
                                       [(ngModel)]="firstName" name="firstName">
                            </div>
                        </div>
                        <div class="col-6">
                            <!-- Last name -->
                            <div class="form-group">
                                <label for="lastName">Last name</label>
                                <input type="text"
                                       class="form-control"
                                       id="lastName"
                                       placeholder="Smith"
                                       required
                                       [(ngModel)]="lastName" name="lastName">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <!-- Email -->
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="email"
                                       class="form-control"
                                       id="email"
                                       placeholder="hello@example.com"
                                       required
                                       [(ngModel)]="email" name="email">
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- Phone Number -->
                            <div class="form-group">
                                <label for="phone">Phone</label>
                                <input type="tel" pattern="^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$"
                                       class="form-control"
                                       id="phone"
                                       placeholder="123-456-7890"
                                       required
                                       [(ngModel)]="phone" name="phone">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <!-- Message -->
                            <div class="form-group">
                                <label for="message">What do you need help with?</label>
                                <textarea class="form-control overflow-hidden"
                                          id="message"
                                          rows="1"
                                          placeholder="..."
                                          oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                                          required
                                          [(ngModel)]="message" name="message"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <!-- Submit -->
                            <button #submit (click)="submitCallback()" class="btn btn-block btn-primary mt-3 lift">
                                Contact Amnon
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- "Thank you" page -->
        <div class="modal-content" [style.display]="finished ? 'flex' : 'none'" [attr.aria-hidden]="!finished">
            <div class="modal-body">
                <!-- Close -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <!-- Image -->
                <div class="text-center">
                    <img src="./assets/img/illustrations/illustration-1.png" alt="..." class="img-fluid mb-3" style="width: 200px;">
                </div>
                <!-- Heading -->
                <h2 class="font-weight-bold text-center mb-1" id="modalTitle">
                    Thank You
                </h2>
                <!-- Text -->
                <p class="font-size-lg text-center text-muted">
                    Thank you for contacting me. I will reach back out to you shortly.
                </p>
            </div>
        </div>
    </div>
</div>
