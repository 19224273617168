import { Component } from '@angular/core';
import { FormComponent, SubmitService } from 'blog-lib';
import { Observable } from 'rxjs';

/**
 * Toggle this with `<button data-toggle="modal" data-target="team-contact-modal[broker-name~='Amnon'] .modal"></button>`.
 */
@Component({
    selector: 'amnon-contact-modal',
    templateUrl: './amnon-contact-modal.component.html'
})
export class AmnonContactModalComponent extends FormComponent {
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    phone: string = '';
    message: string = '';

    constructor(private submitService: SubmitService) {
        super();
    }

    submit(): Observable<string> {
        return this.submitService.submit('broker-contact', {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            brokerName: 'Amnon',
            message: this.message
        });
    }
}
