import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FormComponent, SubmitService } from 'blog-lib';

@Component({
    selector: 'buyers-form',
    templateUrl: './buyers-form.component.html'
})
export class BuyersFormComponent extends FormComponent {
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    phone: string = '';
    movingToMessage: string = '';
    needsMessage: string = '';
    marketingConsent: boolean = false;

    constructor(private submitService: SubmitService) {
        super();
    }

    submit(): Observable<string> {
        return this.submitService.submit('buyers', {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            movingToMessage: this.movingToMessage,
            needsMessage: this.needsMessage,
            marketingConsent: this.marketingConsent
        });
    }
}
